import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Collapse } from '@mui/material';
// hooks
import useActiveLink from '../../../hooks/useActiveLink';
import NavItem from './NavItem';
export default function NavList({ data, depth, hasChild }) {
    const { pathname } = useLocation();
    const { active, isExternalLink } = useActiveLink(data.path);
    const [open, setOpen] = useState(active);
    useEffect(() => {
        if (!active) {
            handleClose();
        }
    }, [pathname]);
    const handleToggle = () => {
        setOpen(!open);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(NavItem, { item: data, depth: depth, open: open, active: active, isExternalLink: isExternalLink, onClick: handleToggle }), hasChild && (_jsx(Collapse, { in: open, unmountOnExit: true, children: _jsx(NavSubList, { data: data.children, depth: depth }) }))] }));
}
function NavSubList({ data, depth }) {
    return (_jsx(_Fragment, { children: data.map((list) => (_jsx(NavList, { data: list, depth: depth + 1, hasChild: !!list.children }, list?.title + list.path))) }));
}
