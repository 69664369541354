import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { List, Stack } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
import { StyledSubheader } from './styles';
import NavList from './NavList';
// ----------------------------------------------------------------------
export default function NavSectionVertical({ data, sx, ...other }) {
    const { translate } = useLocales();
    return (_jsx(Stack, { sx: sx, ...other, children: data.map((group) => {
            const key = group?.subheader || group?.items[0]?.title;
            return (_jsxs(List, { disablePadding: true, sx: { px: 2 }, children: [group.subheader && (_jsx(StyledSubheader, { disableSticky: true, children: `${translate(group.subheader)}` })), group.items.map((list) => (_jsx(NavList, { data: list, depth: 1, hasChild: !!list.children }, list?.title + list?.path)))] }, key));
        }) }));
}
